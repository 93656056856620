import * as React from "react"
import Layout from "../components/layout";
import styled from "styled-components";
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { graphql } from "gatsby"
import Obfuscate from 'react-obfuscate';
import { SeoComponent } from "../components/seo";
import breakpoints from "../components/breakpoints";

const Impressum = styled.section`
position: relative;
display: block;
overflow: hidden;
color: #333;
padding: 80px 25% 20px 25%;
@media (max-width: ${breakpoints.tablet}) {
  padding: 80px 10% 20px 10%;
}
`
const Paragraph = styled.p`
color: #ccc;
font-size: 10pt;`

const Source = styled.p`
position: relative;
display: flex;
justify-content: end;
font-size: 8pt;
opacity: 0.2;
color: #999;
a {
    color: #999;
    text-decoration: none;
    font-size: 8pt;
}`

const ImpressumPage = () => {

    const { t } = useTranslation();

    return (

        <Layout >
            <SeoComponent title={t('Impressum-Page.Title')} description={t('Impressum-Page.Description')} />

            <Impressum>
                <h1><Trans>Impressum</Trans></h1>

                <h2><Trans>Impressum-Page.Angaben</Trans></h2>
                <Paragraph>Foxel Media GmbH<br />
                    Oehrenstöcker Chaussee 21<br />
                    98693 Ilmenau</Paragraph>

                <Paragraph><Trans>Impressum-Page.Handelsregister</Trans>: HRB 519017<br />
                    <Trans>Impressum-Page.Registergericht</Trans>: Amtsgericht Jena</Paragraph>

                <Paragraph><strong><Trans>Impressum-Page.Vertreten durch</Trans>:</strong><br />
                    Clarissa Gabriel<br />
                    Denny Nguyen
                </Paragraph>

                <h2><Trans>Kontakt</Trans></h2>
                <Paragraph><Trans>Impressum-Page.Telefon</Trans>: <Obfuscate tel={t('Telefonnummer')} /><br />
                    E-Mail: <Obfuscate email="info@foxel.media" /></Paragraph>

                <h2><Trans>Impressum-Page.Umsatzsteuer-ID</Trans></h2>
                <Paragraph><Trans>Impressum-Page.Umsatzsteuer-Absatz</Trans>:<br />
                    DE348971009</Paragraph>

                <h2><Trans>Impressum-Page.Redaktionell verantwortlich</Trans></h2>
                <Paragraph>Denny Nguyen<br />
                    Oehrenstöcker Chaussee 21<br />
                    98693 Ilmenau</Paragraph>

                <h2><Trans>Impressum-Page.Verbraucher-Headline</Trans></h2>
                <Paragraph><Trans>Impressum-Page.Verbraucher-Absatz</Trans></Paragraph>

                <Source><Trans>Impressum-Page.Quelle</Trans>: <a href="https://www.e-recht24.de/impressum-generator.html" target="_blank" rel="noreferrer">https://www.e-recht24.de/impressum-generator.html</a></Source>
            </Impressum>
        </Layout>

    )
}
export default ImpressumPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;